import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';

export default function PricingTable(props) {
    return (
        <div className="pricing-table">
            {props.content.map((section, i) => {
                return(
                    <div className="section" key={i}>
                        <h3 className="title" dangerouslySetInnerHTML={{__html:section.title}}></h3>

                        <div className="rows">
                            <table>
                                <tbody>
                                    {section.rows.map((row, j) => {
                                        return(
                                            <tr className="row" key={j}>
                                                <td className="type" dangerouslySetInnerHTML={{__html:row.type}}></td>
                                                <td className="quantity" dangerouslySetInnerHTML={{__html:row.quantity}}></td>
                                                <td className="level"><img src={media[row.level]} /></td>
                                                <td className="price" dangerouslySetInnerHTML={{__html:row.price}}></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}
