import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';

export default function Paragraphs(props) {
    return (
        <div className="stock-boxes">
            {props.content.map((box, key) => {
                return(
                    <div className="box" key={key}>
                        <div className="inner-box">
                            <div className="inner-row">
                                <img className="icon" src={media[box.icon]} />

                                <span className="label" dangerouslySetInnerHTML={{__html:box.label}}></span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}
