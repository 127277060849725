import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';
import Paragraphs from "../../components/paragraphs/main";

export default function ContactBox(props) {
    return (
        <div className="contact-box">
            <img className="willows1" src={media["willows_icon3"]} />
            <img className="willows2" src={media["willows_icon4"]} />

            <div className="rows">
                {props.content.map((row, i) => {
                    return(
                        <div className={"row "+(i%2===0?"row-left":"row-right")} key={i}>
                            <div className="img-holder">
                                <img src={media[row.img]} />
                            </div>

                            <div className="txt-holder">
                                <div className="txt-wrapper">
                                    <span className="title">{row.title}</span>
                                    <Paragraphs content={row.paragraphs} />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}
