import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";

export default function NotFound(props) {

    return (
        <div className="not-found">
            <Layout>
                <div className="intro-block">
                    <div className="inner-content">
                        {config.intro.heading}
                    </div>
                </div>
            </Layout>
        </div>
    )
}
