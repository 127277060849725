import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import {scrollToElementId} from '../../utilities/general';


export default function Topbar(props) {
    const [showPanel, setShowPanel] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        }
    }, []);

    return (
        <div className="topbar">
            <div className="inner-content">
                <a className="logo" href="/"></a>

                <div className="menu">
                    <nav className="tabs">
                        {config.tabs.map((tab, key) => {
                            return(
                                <a className="tab" key={key} onClick={()=>{onTabClick(tab)}}>{tab.name}</a>
                            );
                        })}
                    </nav>
                </div>
            </div>

            <div className={"overlay"+(showPanel?" open":"")}>
                <div className="panel">
                    <nav className="tabs">
                        {config.tabs.map((tab, key) => {
                            return(
                                <a className="tab" key={key} onClick={()=>{onTabClick(tab)}}>
                                    <span className="text">{tab.name}</span>
                                </a>
                            );
                        })}
                    </nav>
                </div>
            </div>

            <a className="menu-btn" onClick={onMenuClick}>
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
            </a>
        </div>
    )

    function onTabClick(tab) {
        setShowPanel(false); //Hide mobile panel if it is open

        scrollToElementId(tab.elementId);
    }

    function onMenuClick(e) {
        e.preventDefault();

        if (showPanel) {
            setShowPanel(false);
        }
        else {
            setShowPanel(true);
        }
    }

    function onWindowResize() {
        setShowPanel(false);
    }
}
