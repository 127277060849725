const media = {

    //Images

    topbar_logo: require("./imgs/topbar_logo.svg").default,

    willows_icon1: require("./imgs/willows_icon1.svg").default,
    willows_icon2: require("./imgs/willows_icon2.svg").default,
    willows_icon3: require("./imgs/willows_icon3.svg").default,
    willows_icon4: require("./imgs/willows_icon4.svg").default,
    willows_icon5: require("./imgs/willows_icon5.svg").default,
    willows_icon6: require("./imgs/willows_icon6.svg").default,

    stock_icon1: require("./imgs/stock_icon1.svg").default,
    stock_icon2: require("./imgs/stock_icon2.svg").default,
    stock_icon3: require("./imgs/stock_icon3.svg").default,
    stock_icon4: require("./imgs/stock_icon4.svg").default,

    intro_banner: require("./imgs/intro_banner.png"),
    intro_img1: require("./imgs/intro_img1.png"),
    intro_img2: require("./imgs/intro_img2.png"),
    intro_img3: require("./imgs/intro_img3.png"),

    uses_tile1: require("./imgs/uses_tile1.png"),
    uses_tile2: require("./imgs/uses_tile2.png"),
    uses_tile3: require("./imgs/uses_tile3.png"),
    uses_tile4: require("./imgs/uses_tile4.png"),
    uses_tile5: require("./imgs/uses_tile5.png"),
    uses_img1: require("./imgs/uses_img1.png"),
    uses_img2: require("./imgs/uses_img2.png"),
    uses_img3: require("./imgs/uses_img3.png"),
    uses_img4: require("./imgs/uses_img4.png"),
    uses_img5: require("./imgs/uses_img5.png"),
    uses_img6: require("./imgs/uses_img6.png"),

    products_img1a: require("./imgs/products_img1a.png"),
    products_img1b: require("./imgs/products_img1b.png"),
    products_img2a: require("./imgs/products_img2a.jpg"),
    products_img2b: require("./imgs/products_img2b.jpg"),
    products_img3a: require("./imgs/products_img3a.jpg"),
    products_img3b: require("./imgs/products_img3b.jpg"),
    products_img4a: require("./imgs/products_img4a.jpg"),
    products_img4b: require("./imgs/products_img4b.jpg"),

    about_img1: require("./imgs/about_img1.png"),
    about_img2: require("./imgs/about_img2.png"),

    contact_img1: require("./imgs/contact_img1.png"),
    contact_img2: require("./imgs/contact_img2.png"),
}

export default media;