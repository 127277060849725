import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import SectionHeading from "../../components/section-heading/main";
import Paragraphs from "../../components/paragraphs/main";
import IntroBoxes from "../../components/intro-boxes/main";
import GalleryBlocks from "../../components/gallery-blocks/main";
import Accordion from "../../components/accordion/main";
import VideoBox from "../../components/video-box/main";
import StockBoxes from "../../components/stock-boxes/main";
import PricingTable from "../../components/pricing-table/main";
import ContactBox from "../../components/contact-box/main";


export default function Home(props) {

    //On component mount
    useEffect(() => {

    }, []);

    return (
        <div className="home">
            <Layout>
                <div className="intro-block">
                    <div className="inner-content">
                        <img className="banner" src={media[config.intro.banner]} />

                        <SectionHeading content={config.intro.heading} />

                        <Paragraphs content={config.intro.paragraphs} />

                        <IntroBoxes content={config.intro.boxes} />
                    </div>
                </div>

                <div id="uses"></div>

                <div className="uses-block">
                    <div className="inner-content">
                        <SectionHeading content={config.uses.heading} />

                        <div className="tiles">
                            <div className="row row1">
                                <div className="col col1">
                                    <img src={media[config.uses.img1]} />
                                </div>
                                <div className="col col2">
                                    <img src={media[config.uses.img2]} />
                                </div>
                            </div>

                            <div className="row row2">
                                <div className="col col1">
                                    <div className="sub sub1">
                                        <img src={media[config.uses.img3]} />
                                    </div>
                                    <div className="sub sub2">
                                        <img src={media[config.uses.img4]} />
                                    </div>
                                </div>
                                <div className="col col2">
                                    <img src={media[config.uses.img5]} />
                                </div>
                            </div>
                        </div>

                        <div className="info-box">
                            <img className="willows1" src={media["willows_icon1"]} />
                            <img className="willows2" src={media["willows_icon2"]} />
                            <p className="info">{config.uses.info}</p>
                        </div>

                        <GalleryBlocks content={config.uses.blocks} />
                    </div>
                </div>

                <div id="products"></div>

                <div className="products-block">
                    <div className="inner-content">
                        <SectionHeading content={config.products.heading} />

                        <div className="accordion-holder">
                            <Accordion bars={config.products.names} type="multiple">
                                {config.products.descriptions.map((description, i) => {
                                    return(
                                        <div className="description" key={i}>
                                            <div className="imgs-holder">
                                                {description.imgs.map((img, j) => {
                                                    return(
                                                        <div className="img-wrapper" key={j}>
                                                            <img src={media[img]} />
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            <div className="txt-holder">
                                                <Paragraphs content={description.paragraphs} />
                                            </div>
                                        </div>
                                    );
                                })}
                            </Accordion>
                        </div>
                    </div>
                </div>

                <div id="about"></div>

                <div className="about-block">
                    <div className="inner-content">
                        <SectionHeading content={config.about.heading} />

                        <div className="about-box">
                            <img className="willows1" src={media["willows_icon3"]} />
                            <img className="willows2" src={media["willows_icon4"]} />

                            <div className="box-inner">
                                <div className="rows">
                                    {config.about.rows.map((row, i) => {
                                        return(
                                            <div className={"row"+(row.img?" has-img":"")} key={i}>
                                                {row.img && <img className="img" src={media[row.img]} />}

                                                <Paragraphs content={row.paragraphs} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="pricing"></div>

                <div className="pricing-block">
                    <div className="inner-content">
                        <SectionHeading content={config.pricing.heading} />

                        <div className="paragraphs1">
                            <Paragraphs content={config.pricing.paragraphs1} />
                        </div>

                        <StockBoxes content={config.pricing.boxes} />

                        <div className="paragraphs2">
                            <Paragraphs content={config.pricing.paragraphs2} />
                        </div>

                        <PricingTable content={config.pricing.table} />

                        <div className="paragraphs3">
                            <Paragraphs content={config.pricing.paragraphs3} />
                        </div>
                    </div>
                </div>

                <div id="contact"></div>

                <div className='contact-block'>
                    <div className="inner-content">
                        <ContactBox content={config.contact} />
                    </div>
                </div>
            </Layout>
        </div>
    )
}
