export function replaceStrPatterns(original, replacements) {
    var result = original;
    var i;

    for (i=0; i<replacements.length; i++) {
        result = result.replace(new RegExp(replacements[i].pattern, 'g'), replacements[i].value);
    }

    return result;
}

export function scrollToElementId(id) {
    var element = document.getElementById(id);

    if (element!==null) {
        element.scrollIntoView({behavior:'smooth'});
    }
}
