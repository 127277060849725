import React, {useEffect, useState} from 'react';
import './styles/general.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {scrollToElementId} from './utilities/general';

//Pages
import Home from "./pages/home/main";
import NotFound from "./pages/not-found/main";

export default function Web() {

    //On component mount
    useEffect(() => {
        var hash = window.location.hash;

        if (hash!=="") {
            scrollToElementId(hash.slice(1)); //Remove first character to get id
        }
    }, []);

    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route index element={<Home />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
