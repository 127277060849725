import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import {replaceStrPatterns} from '../../utilities/general';

export default function Footer(props) {
    const [currYear, setCurrYear] = useState(new Date().getFullYear());

    return (
        <footer className="footer">
            <div className="inner-content">
                <span className="copyright">{replaceStrPatterns(config.copyright, [{pattern:"{{year}}", value:currYear}])}</span>
            </div>
        </footer>
    )
}
