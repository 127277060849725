import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';

export default function Paragraphs(props) {
    return (
        <div className="intro-boxes">
            {props.content.map((box, key) => {
                return(
                    <div className="box" key={key}>
                        <div className="box-content">
                            <div className="image" style={{backgroundImage:"url("+media[box.img]+")"}}></div>
                            <div className="overlay"></div>

                            <div className="text-holder">
                                <span className="title">{box.title}</span>
                                <span className="text">{box.text}</span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}
