import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';

export default function GalleryBlocks(props) {
    return (
        <div className="gallery-blocks">
            {props.content.map((block, i) => {
                return(
                    <div className={"block "+(i%2===0?"block-left":"block-right")} key={i}>
                        <div className="img-holder">
                            <img src={media[block.img]} />
                        </div>

                        <div className="txt-holder">
                            <div className="txt-wrapper">
                                <span className="title">{block.title}</span>
                                <p className="info">{block.info}</p>
                                <span className="perfect">{block.perfect}</span>

                                <div className="examples">
                                    {block.examples.map((example, j) => {
                                        return(
                                            <span className="example" key={j}>
                                                {example}
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}
